import { FC, Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { FormattedMessage } from 'react-intl';
import './dropdown-list.scss';
import { ROUTE_PATH } from '../../../routes/utils';
import { IDropDownProps } from './types';

const DropDownList: FC<IDropDownProps> = ({ data, sidebar, callbackAction, langCode, translated }) => {
	const [open, setOpen] = useState<{ [k: number]: boolean }>({ 0: false });

	useEffect(() => {
		if (!sidebar) {
			setOpen({
				0: false,
			});
		}
	}, [sidebar]);

	const handleClick = (value: number) => {
		setOpen({ [value]: !open[value] });

		if (!open[value] && !sidebar) {
			callbackAction();
		}
	};

	const ListData = data?.columns?.map((item, idx) => {
		const Children = data?.items?.map((subItem, subIndex) => {
			if (item.id === subItem.column_id && subItem.display_status_id === 1) {
				const label = translated ? subItem.content_title : <FormattedMessage id={subItem.content_title} />;
				return (
					<li key={`sub-list-item-${subIndex}`} onClick={()=>{callbackAction();}}>
						<NavLink 
							to={`${ROUTE_PATH.root}${langCode}${subItem.url}`} 
							target={subItem.link_action_type !== 1 ? '_blank' : undefined} 
							className="menu_subitem">{label}
						</NavLink>
					</li>
				);
			} else {
				return null;
			}
		});

		const label = translated ? item.title : <FormattedMessage id={item.title} />;

		return (
			<Fragment key={`drop-list-item-${idx}`}>
				<li key={`list-item-${idx}`}>
					<div onClick={() => handleClick(idx)} className={`menu_item icon_${item.id}`}>
						{label}
						{Children.length > 0 && open[idx] ? <span className="icon_up"></span> : Children.length > 0 && !open[idx] ? <span className="icon_down"></span> : null}
					</div>
					{Children.length > 0
						? <Collapse className="drop_block" in={open[idx]} timeout="auto" unmountOnExit>
							<ul className="menu_sublist">
								{Children}
							</ul>
						</Collapse>
						: null}
				</li>

			</Fragment>

		);
	});

	return (
		<ul>
			{ListData}
		</ul>
	);
};


export default DropDownList;
