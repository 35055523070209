import { useAppSelector } from '../../../../hooks/storeHooks';
import { bannersSelectors } from '../../../../redux/banners/selectors';
import React from 'react';

const {
	selectSidebarBlog,
} = bannersSelectors;
const LeftBlock: React.FC = ()=>{
	const sidebar_blog = useAppSelector(selectSidebarBlog);

	return (
		<div>
			<div className="footer_logo">
				<img src="/resources/images/svg/auroom_logo_horizontal.svg" alt="" title="" />
			</div>
			<div className="responsibility">
				<div className="description_block"> {sidebar_blog.map((item) => item.description)} </div>
			</div>
		</div>
	);
};
export default LeftBlock;
