import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import actions from '../../redux/notifications/actions';
import { absolutePath, notificationsTypes } from '../../helpers/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { notificationsSelectors } from '../../redux/notifications/selectors';
import { notificationReducers } from '../../redux/notifications/slice';
import { INotification } from '../../redux/notifications/types';
import { typeDate } from '../controls/date/utils';
import Date from '../controls/date';
import { TABS } from '../../redux/menu/types';
import { menuReducers } from '../../redux/menu/slice';
import { INotificationItemProps } from './types';
import defaultBonusImage from '../../resources/images/bonus-default.png';

const {
	setBottomMenuUI,
} = menuReducers;

const {
	selectUI,
} = notificationsSelectors;
const {
	setUI: setNotificationUI,
} = notificationReducers;
const NotificationItem: React.FC<INotificationItemProps> = ({ item }) => {
	
	// const bonusData = useAppSelector(selectBonusData);
	const { markAllLoading } = useAppSelector(selectUI);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { locale } = useParams();
	const { pathname } = useLocation();


	const markRead = ({ id, bonusID, seen, loading, type }: INotification) => {
		if (loading || markAllLoading){
			return;
		}
		
		!seen && dispatch(actions.markReadAction({ id }));
		if (Number(type) === notificationsTypes.bonusNotifications) {
			dispatch(setNotificationUI({ dropDownVisible: false }));

			const bonusPage = `/${locale}/profile/bonus#${bonusID}`;
			if (pathname !== bonusPage) {
				navigate(bonusPage);
				dispatch(setBottomMenuUI({ notificationVisible: false, activeTabBottomMenu: TABS.NONE }));
			}
		}
	};

	const ItemLink = item.action && absolutePath(item.action)
		? <a className={'menu_item'} rel="noopener noreferrer" href={item.action} target='_blank'>{item.title}</a>
		: item.action && !absolutePath(item.action)
			? <Link className={'menu_item'} to={item.action}>{item.title}</Link>
			: <div className={'menu_item'}>{item.title}</div>;

	return (
		<li onClick={() => markRead(item)} className={!item.seen ? 'new_income' : ''}>
			<img src={item?.data?.url ? `${item.data.url}` : defaultBonusImage} alt="" title=""/>
			<div className="not_info">
				{ItemLink}
				<p className='desc_menu_item'>{item.body && item.body}</p>
				<div className="not_date"><Date date={item.created} type={typeDate.DIFF_DATE}/></div>
			</div>
		</li>
	);
};

export default NotificationItem;
